@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-sky-800;
}

canvas {
  z-index: 2;
}

video,
canvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Need for setting up the fullscreen background programmatically during runtime */
.fullscreen:fullscreen {
  user-select: none;
  --backgroundColor: #fff;
  background-color: var(--backgroundColor);
}

details>summary {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

details>summary::-webkit-details-marker {
  display: none;
}

details>summary::before {
  content: "";
  display: none;
}

details>summary::after {
  display: inline-block;
  content: '\2303';
  transform: scale(-1);
  transition: 0.2s ease;

}

details[open]>summary::after {
  content: '\2303';
  transform: none;

}