.progress-loader {
    height: 4px;
    width: 100%;
    --b1: no-repeat linear-gradient(#0c4a6e 0 0);
    --b2: #f0f9ff;
    background: var(--b1), var(--b1), var(--b2);
    background-size: 60% 100%;
    animation: l16 3s infinite;
}

@keyframes l16 {
    0% {
        background-position: -150% 0, -150% 0
    }

    66% {
        background-position: 250% 0, -150% 0
    }

    100% {
        background-position: 250% 0, 250% 0
    }
}